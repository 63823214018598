<template>
  <div class="flex-shrink-0 flex-grow-1 p-md-6 py-sm-3 px-sm-0">
    <div class="mw-100" style="width: 26.25rem; margin:0 auto;">
      <div class="card text-center">
        <div class="card-body mx-6 mb-6 mt-5 p-0">

          <h1 class="card-title mb-5">Reset Password</h1>

          <i>Passwords must be at least 6 characters, no
          more than 20 characters, and must include at
          least one upper case letter, one lower case
          letter, and one numeric digit.</i>
          <br><br>
          
          <form id="resetPassword" class="text-left mw-1000" autocomplete="off" @submit.prevent="resetPassword">

            <div class="col-sm-12">
              <div class="form-group">
                <label for="email" class="sr-only">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  placeholder="Email (Required)" 
                  v-model="$v.form.email.$model" 
                  class="form-control form-control-lg mb-5 text-center"
                  :class="{
                    'is-valid': !$v.form.email.$error && $v.form.email.$dirty,
                    'is-invalid': $v.form.email.$error
                  }"
                />
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <label for="resetcode" class="sr-only">Reset Code</label>
                <input 
                  type="text" 
                  id="resetcode" 
                  placeholder="Reset Code (Required)" 
                  autocomplete="off" 
                  v-model="$v.form.resetcode.$model" 
                  class="form-control form-control-lg mb-5 text-center"
                  :class="{
                    'is-valid': !$v.form.resetcode.$error && $v.form.resetcode.$dirty,
                    'is-invalid': $v.form.resetcode.$error
                  }"
                />
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <label for="newPassword" class="sr-only">New Password</label>
                <input 
                  type="password" 
                  id="newPassword" 
                  v-model="$v.form.newPassword.$model"
                  placeholder="New Password" 
                  autocomplete="off" 
                  class="form-control form-control-lg mb-5 text-center"
                  :class="{
                    'is-valid': !$v.form.newPassword.$error && $v.form.newPassword.$dirty,
                    'is-invalid': $v.form.newPassword.$error
                  }"
                />
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <label for="confirm_password" class="sr-only">Confirm Password</label>
                <input 
                  type="password" 
                  id="confirmPassword" 
                  v-model="$v.form.confirmPassword.$model"
                  placeholder="Confirm Password" 
                  autocomplete="off" 
                  class="form-control form-control-lg mb-5 text-center"
                  :class="{
                    'is-valid': !$v.form.confirmPassword.$error && $v.form.confirmPassword.$dirty,
                    'is-invalid': $v.form.confirmPassword.$error
                  }"
                />
              </div>
            </div>

            <button type="submit" :disabled="$v.form.$invalid" class="btn btn-primary btn-block">Submit</button>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { pcMixin } from '@/mixins/pcMixin.js';
import { apiMixin } from '@/mixins/apiMixin.js';

import { email } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';
import { sameAs } from 'vuelidate/lib/validators';

import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

// Passwords must be at least 6 characters,
// no more than 20 characters,
// and must include at least one upper case letter,
// one lower case letter, and one numeric digit.
const pwrules = helpers.regex('alpha', /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);

export default {
  name: 'ResetPasswordView',

  mixins: [ pcMixin, apiMixin ],

  data() {
    return {
      form: {
        email: null,
        resetcode: null,
        newPassword: null,
        confirmPassword: null
      }
    }
  },

  validations: {
    form: {
      email: {
        email,
        required
      },
      resetcode: {
        minLength: 7,
        required
      },
      newPassword: { 
        required, 
        pwrules
      },
      confirmPassword: { 
        sameAsPassword: sameAs('newPassword')
      }
    }
  },

  methods: {
    
    async resetPassword() {
      this.pcSpinnerShow();

      // Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "resetpw".
      const token = await this.$recaptcha('resetpw')

      this.apiResetPw(this.form.email, this.form.resetcode, this.form.newPassword, token)
      .then(() => {
        this.pcModal("Your password has been changed")
        .then(() => { this.$router.push({ name: 'SignInRoute' }) })
        .catch(() => {})
      })
      .catch((error) => { 
        if (error.response.status == 429) {
          this.pcModal("You have exceeeded the maximum number of password reset attempts. Please request a new Password Reset code.")
          .then(() => { this.$router.push({ name: 'SignInRoute' }) })
          .catch(() => {})
        } else { 
          this.pcModal("Invalid email/activation code combination").catch(() => {});
        }
      })
      .finally(() => { this.pcSpinnerHide(); });
    }
  }
}
</script>

<style scoped>
  .wide {
    width: 300px;
  }
</style>
